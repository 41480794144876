/* Ensure the container takes up the full viewport */
.background-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    z-index: -1; /* Make sure video is behind other content */
    overflow: hidden;
}

/* Style the video to cover the entire screen */
.background-video {
    position: absolute;
    top: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
}

.mobile-videos {
    display: flex; /* Use flexbox to align videos */
    flex-direction: column; /* Stack videos vertically */
    height: 100%; /* Full height for mobile videos */
}

.mute-overlay {
    position: absolute;
    bottom: 20px;
    left: 20px;
    background-color: var(--dark-color);
    padding: 12px 20px;
    color: white;
    justify-content: flex-start;
    align-items: center;
    display: flex; /* Added to enable flexbox */
    flex-direction: row; /* Align buttons horizontally */
    gap: 20px; /* Space between choices */
    z-index: 10; /* Ensure overlay is above video */
    border-radius: 6px;
}

.mute-choice {
    border: 2px solid var(--accent-color);
    padding: 4px 12px;
    cursor: pointer; /* Add a pointer cursor for better UX */
    text-align: center; /* Center text horizontally */
    cursor: pointer; /* Add a pointer cursor for better UX */
    border-radius: 4px;
}

.video-controls { 
    position: absolute; 
    bottom: 20px; 
    right: 20px; 
    background-color: rgba(0, 0, 0, 0.3); 
    color: white; 
    padding: 12px 20px; 
    border-radius: 6px; 
}

@media screen and (max-width: 960px) {
    .background-video
    {
        object-position: center center;
        object-fit: cover;
    }
}