.jobs-container {
    max-width: 60%;
    margin: 0 auto;
    padding: 20px;
  }
  
  .about-section,
  .jobs-section,
  .open-application-section
   {
    margin-bottom: 100px;
  }

  .about-section
  {
    text-align: center;
    padding-top: 80px;
    padding-bottom: 20px;
  }
  
  
  h1,
  h2 {
    color: var(--accent-color);
    margin-bottom: 32px;
    font-size: 32px;
    font-weight: 500;
  }
  
  h3 {
    color: var(--accent-color);
    font-weight: 600;
    font-size: 24px;
  }
  
  p {
    font-size: 20px;
    line-height: 1.6;
    font-weight: 300;
    color: var(--readable-white);
    white-space: pre-line;
  }
  
  a {
    color: var(--accent-color);
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  
  .job-opening,
  .open-application {
    border: 2.4px solid var(--steel-color);
    padding: 0px 32px;
    padding-bottom: 16px;
    margin-top: 28px;
    border-radius: 12px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.3);
  }

  @media screen and (max-width: 960px) {
    .jobs-container {
      max-width: 90%;
      padding: 10px;
    }
  
    h1, h2 {
      font-size: 24px;
      margin-bottom: 24px;
    }
  
    h3 {
      font-size: 20px;
      margin-bottom: 16px;
    }
  
    p {
      font-size: 18px;
      line-height: 1.4;
    }
  
    .job-opening, .open-application {
      padding: 0 16px;
      padding-bottom: 12px;
      margin-top: 24px;
      border-radius: 8px;
    }
  
    .about-section {
      padding-top: 40px;
      padding-bottom: 10px;
      padding-right: 16px;
      padding-left: 16px;
    }
  
    .about-section, .jobs-section, .open-application-section {
      margin-bottom: 50px;
    }
  }
  