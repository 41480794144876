.privacy-policy {
    line-height: 1.6;
    margin: 0 auto;
    max-width: 800px;
    padding: 20px;
    color: white;
  }
  
  .privacy-policy h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .privacy-policy h2 {
    font-size: 1.8rem;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .privacy-policy p {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
  
  .privacy-policy ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  .privacy-policy ul li {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
  
  .privacy-policy a {
    color: var(--accent-color);
    text-decoration: none;
  }
  
  .privacy-policy a:hover {
    text-decoration: underline;
  }
  