
.support-container {
  max-width: 600px;
  margin: 40px auto; /* Center the container */
  padding: 30px;
  text-align: center;
}

.support-heading {
  font-size: 2.5rem; /* Heading size */
  color: var(--readable-white); /* Heading color */
  margin-bottom: 20px; /* Space below heading */
}

.support-text {
  font-size: 1.2rem; /* Text size */
  color: var(--grey-color); /* Text color */
  margin-bottom: 30px; /* Space below text */
}

.support-contact-info {
  display: flex;
  flex-direction: column;
  gap: 16px; /* Space between items */
}

.support-contact-item {
  display: flex; /* Flexbox for icon and text alignment */
  flex-direction: row;
  align-items: center; /* Center items vertically */
  font-size: 1.2rem; /* Default item text size */
  background: var(--dark-color); /* Dark background for contact items */
  padding: 15px;
}

.support-contact-item:hover {
  border-color: var(--accent-color); /* Change border color on hover */
}

.support-icon {
  margin-right: 10px; /* Space between icon and text */
  color: var(--accent-color); /* Icon color */
  font-size: 1.5rem; /* Icon size */
}

.support-title {
  font-size: 1.2rem; /* Smaller title size */
  color: var(--steel-color); /* Steel color for titles */
  margin: 0; /* Remove margin */
}

.support-link {
  margin-left: auto; /* Align link to the right */
  color: var(--accent-color); /* Link color */
  text-decoration: none;
  border: 1px solid var(--accent-color); /* Accent color border for button-like effect */
  border-radius: 5px; /* Rounded corners */
  padding: 5px 10px; /* Padding for button */
  transition: background 0.3s, color 0.3s; /* Smooth transitions */
}

.support-link:hover {
  background-color: var(--accent-color); /* Change background on hover */
  color: var(--readable-white); /* Change text color on hover */
}

/* Responsive Styles */
@media screen and (max-width: 960px) {
  .support-container {
    padding: 20px;
  }

  .support-heading {
    font-size: 2rem;
  }

  .support-text {
    font-size: 1rem;
  }

  .support-contact-item {
    font-size: 1.1rem;
  }
}