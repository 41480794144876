.download-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }
  
  .download-content {
    text-align: center;
    padding: 40px;
  }
  
  .download-content h1 {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 16px;
    color: white;
  }
  
  .download-content p {
    font-size: 18px;
    color: white;
    margin-bottom: 32px;
  }
  
  .download-buttons {
    display: flex;
    justify-content: center;
    gap: 40px;
  }
  
  .download-button {
    background-color: black !important; /* Button background color */
    color: white !important;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    transition: background-color 0.3s ease;
  }
  
  .download-button:hover {
    background-color: #2c6bb2; /* Darker shade on hover */
  }

@media screen and (max-width: 960px) {
    .download-content h1 {
      font-size: 24px;
    }
    
    .download-content p {
      font-size: 16px;
    }
  }
  
  @media screen and (max-width: 600px) {
    .download-content {
      padding: 20px;
    }
    
    .download-content h1 {
      font-size: 20px;
    }
    
    .download-content p {
      font-size: 14px;
    }
    
    .download-buttons {
      flex-direction: column;
      align-items: center;
      gap: 20px;
    }
  
}
  