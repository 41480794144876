.footer {
    width: 100%;
    padding: 24px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    text-align: center;
    color: white;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.42px;
}

.column
{
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.footer-link {
    color: inherit; /* Inherit the current text color (white) */
    text-decoration: none; /* Remove the underline */
}

.footer-link:hover {
    text-decoration: underline; /* Underline on hover */
}

  
@media (max-width: 768px) {
    .footer {
        flex-direction: column;
        gap: 40px;
    }
  
    .footer div {
        width: 100%;
    }
  }