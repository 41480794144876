.big-button {
    cursor: pointer;
    background-color: transparent;
    border: 2px solid var(--accent-color);
    border-radius: 8px;
    transition: all 0.3s ease-out; 
    justify-content: center;
}

.big-button:hover {
    background-color: var(--accent-color);
}

.big-button-content {
    color: white;
    display: inline-flex;
    padding: 14px 20px;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    text-align: start;
    gap: 8px;
}

.big-button-icon {
    color: var(--accent-color);
    stroke-width: 2px;
    width: 24px;
    height: 24px;
}

.big-button:hover .big-button-icon{
    color: white;
}

.mobile-button {
    cursor: pointer;
    background-color: transparent;
    border: 2px solid var(--accent-color);
    border-radius: 8px;
    transition: all 0.3s ease-out; 
    justify-content: center;
}

.mobile-button:hover {
    background-color: var(--accent-color);
}

.mobile-button-content {
    color: white;
    display: inline-flex;
    padding: 6px 12px;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    text-align: start;
    gap: 6px;
}

.mobile-button-icon {
    color: var(--accent-color);
    stroke-width: 1.5px;
    width: 16px;
    height: 16px;
}

.mobile-button:hover .mobile-button-icon{
    color: white;
}