.location-card-container {
    flex-shrink: 0;
    width: 40vw; /* 40% of the viewport width */
    height: 100%; /* Take full row height */
    background-position: center;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .location-card {
    width: 97%;
    height: 95%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 12px;
    overflow: hidden;
    border: 3.2px #0e1729 solid;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
  }
  
  .location-info {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(9, 13, 20, 0.8) 0%,
      rgba(9, 13, 20, 0.75) 10%,
      rgba(9, 13, 20, 0.33) 100%
    );
    padding: 20px;
    border-radius: 12px;
  }
  
  .location-title {
    text-align: center;
    color: white;
    font-size: 36px;
    font-weight: 600;
    word-wrap: break-word;
  }
  
  .location-location-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
  }
  
  .location-icon {
    position: relative;
    width: 20px;
    height: 20px;
    color: #b3b3b3;
  }
  
  .outer-circle {
    width: 15px;
    height: 18.33px;
    position: absolute;
    left: 2.5px;
    top: 0.83px;
    border: 1.5px solid #b3b3b3;
  }
  
  .inner-circle {
    width: 5px;
    height: 5px;
    position: absolute;
    left: 7.5px;
    top: 5.83px;
    border: 1.5px solid #b3b3b3;
  }
  
  .location-location {
    color: #b3b3b3;
    font-size: 20px;
    font-weight: 500;
    word-wrap: break-word;
  }
  
  .coming-soon-card {
    width: 97%;
    height: 95%;
    background: radial-gradient(68.41% 68.41% at 50% 50%, #181C22 0%, #090D14 100%);
    border-radius: 12px;
    overflow: hidden;
    border: 3.2px #0e1729 solid;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .coming-soon-text {
    text-align: center;
    color: white;
    font-size: 36px;
    font-weight: 600;
    word-wrap: break-word;
  }
  

  @media screen and (max-width: 960px) {
    .location-card-container {
        width: 70vw; /* Three equal rows on mobile */
    }

    .coming-soon-text {
      font-size: 24px;
    }

    .location-title {
      font-size: 24px;
    }

    .location-location {
      font-size: 16px;
    }

    .location-icon {
      width: 16px;
      height: 16px;
    }

    .location-location-container {
      gap: 8px;
    }

    .location-info{
      gap: 4px;
    }
}