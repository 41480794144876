.content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  /* Intro Section */
  .introSection {
    align-self: stretch;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20% 0;
    gap: 40px;
  }
  
  .description, .introduction {
    width: 50%;
    color: white;
    font-size: 20px;
    word-wrap: break-word;
  }
  
  .boldText {
    font-weight: 600; /* Use bold weight */
  }
  
  .mediumText {
    font-weight: 400; /* Use medium weight */
    white-space: pre-line;
  }
  
  .quote {
    width: 823px;
    text-align: center;
    color: white;
    font-size: 20px;
    font-weight: 700; /* Bold for emphasis */
    word-wrap: break-word;
  }
  
  /* Team Section */
  .teamSection {
    align-self: stretch;
    padding: 50px 20px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  
  .teamMember {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    cursor: pointer;
  }
  
  .teamMemberImg {
    width: 240px;
    height: 240px;
    border-radius: 50%;
  }
  
  .teamMemberInfo {
    text-align: center;
    line-height: 1.6;
  }
  
  .teamMemberName {
    color: #3987E3;
    font-size: 20px;
    font-weight: 700; /* Bold weight */
  }
  
  .teamMemberRole {
    color: white;
    font-size: 16px;
    font-weight: 700; /* Bold weight */
    line-height: 19.82px;
  }
  
  /* Featured Member Section */
  .featuredMemberSection {
    display: flex;
    justify-content: start;
    padding: 50px 24px;
    gap: 40px;
    width: 100%;
  }
  
  .featuredMemberImg {
    width: 360px;
    height: 360px;
    border-radius: 50%;
  }
  
  .featuredMemberDetails {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 260px;
    gap: 8px;
  }
  
  .featuredMemberName {
    text-align: start;
    color: #3987E3;
    font-size: 32px;
    font-weight: 700; /* Bold weight */
    word-wrap: break-word;
  }
  
  .featuredMemberRole {
    color: white;
    font-size: 20px;
    font-weight: 700; /* Bold weight */
    line-height: 24.77px;
  }
  
  .featuredMemberContact {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
  
  .contactDetail {
    text-align: start;
    color: #3A3D43;
    font-size: 20px;
    font-weight: 600;
    line-height: 24.77px;
    text-decoration-line: none;
  }
  
  .featuredMemberStory {
    display: flex;
    width: 45%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 20px;
    font-weight: 500;
    line-height: 24.27px;
    gap: 40px;
  }
  
  /* Footer Section */
  .footerSection {
    align-self: stretch;
    padding: 64px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 100px;
  }
  
  .footerContent {
    width: 304px;
    color: white;
    font-size: 16px;
    font-weight: 500; /* Medium weight */
    line-height: 19.42px;
    word-wrap: break-word;
  }

  .socialSection {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 10%;
    margin-top: 10%;
    margin-bottom: 5%;
  }
  
  .socialLink {
      cursor: pointer;
      color: #3A3D43
  }

@media screen and (max-width: 960px) {
    .description, .introduction {
        width: 80%;
    }
    
    .quote {
        width: 80%;
    }
    
    .teamSection {
        flex-direction: column;
        gap: 40px;
    }
    
    .teamMember {
        width: 100%;
    }
    
    .teamMemberImg {
        width: 100%;
        height: 100%;
    }
    
    .teamMemberInfo {
        text-align: center;
    }
    
    .featuredMemberSection {
        flex-direction: column;
        gap: 40px;
    }
    
    .featuredMemberImg {
        width: 100%;
        height: 100%;
    }
    
    .featuredMemberDetails {
        gap: 16px;
    }
    
    .featuredMemberContact {
        gap: 12px;
    }
    
    .featuredMemberStory {
        gap: 20px;
    }
    
    .footerSection {
        flex-direction: column;
        gap: 40px;
    }
    
    .footerContent {
        width: 100%;
        text-align: center;
    }
    
    .socialSection {
        flex-direction: column;
        gap: 5%;
    }
  
}