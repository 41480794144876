.carousel-container {
    width: 100%;
    height: 100%; /* Full viewport height */
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
  }
  
  .carousel-wrapper {
    display: flex;
    height: 100%;
    flex-direction: column;
    overflow-x: scroll;
    scroll-behavior: auto;
    user-select: none;
    white-space: nowrap;
    position: relative;
    scrollbar-width: none;
    cursor: grab;
    z-index: 10;
  }

  .carousel-wrapper:active {
    cursor: grabbing;
  }

  .carousel-wrapper::-webkit-scrollbar {
    display: none;
  }
  
  .carousel-row {
    display: flex;
    flex-direction: row;
    height: 50%; /* Each row takes 50% of the viewport height */
    align-items: center;
    white-space: nowrap;
  }
  
  .row2 {
    /*transform: translateY(-20%); /* Slight vertical offset */
    margin-left: calc(100vw / 6); /* Horizontal offset */
  }
  
  .gradient {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100px;
    z-index: 11;
    pointer-events: none; /* Ensure scrolling is not affected by the gradient */
  }
  
  .gradient-left {
    left: 0;
    background: linear-gradient(to right, rgba(9, 13, 20, 1), rgba(9, 13, 20, 0));
  }
  
  .gradient-right {
    right: 0;
    background: linear-gradient(to left, rgba(9, 13, 20, 1), rgba(9, 13, 20, 0));
  }
  
  @media screen and (max-width: 960px) {
    .carousel-row {
        height: 33.33%; /* Three equal rows on mobile */
    }
}