.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-y: hidden;
}

.mainSection {
  display: flex;
  overflow-y: hidden;
}

.leftColumn {
  display: flex;
  flex-direction: column;
  padding: 40px 100px;
}

.textBlock {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  gap: 40px;
}

.semiBold {
  font-weight: 500;
}

.textSection {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.heading {
  width: 100%;
  color: white;
  font-weight: 400;
}

.largeText {
  font-size: 40px;
  font-weight: 600;
  /*line-height: 59.47px;*/
  line-height: 116%;
}

.smallText {
  font-size: 16px;
  line-height: 19.82px;
}

.businessDescription {
  width: 100%;
  color: white;
  font-size: 20px;
  font-weight: 300;
  word-wrap: break-word;
}

.contactButton {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 16px;
  border-radius: 8px;
  border: 2px solid #3987E3;
  justify-content: center;
}

.contactIcon {
  position: relative;
  width: 24px;
  height: 24px;
}

.iconSquare {
  position: absolute;
  width: 10px;
  height: 10px;
  left: 7px;
  top: 7px;
  border: 2px solid #3987E3;
}

.customerFlow {
  align-self: stretch;
  color: white;
  font-size: 20px;
  line-height: 24.78px;
  font-weight: 600;
}

.mainImage {
  width: 40%;
  display: flex;
  height: 100%;
  object-fit: contain;
}

.contentSections {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 64px;
  overflow-y: hidden;
}

.optionsSection {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 32px 0;
}

.optionBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.optionTitle {
  display: flex;
  flex-direction: row;
  justify-content: bottom;
  font-size: 24px;
  font-weight: 500;
}

.optionHeading {
  color: #3987E3;
}

.optionText {
  color: #FAFAFA;
}

.optionDescription {
  color: #B3B3B3;
  font-size: 20px;
}

.orText {
  color: #3987E3;
  font-weight: 600;
  font-size: 20px;
}

.custom-list {
  list-style-type: none;
  padding-left: 0;
}

.custom-list li {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.list-number {
  color: var(--accent-color);
  font-weight: 400;
  margin-right: 10px;
}

.list-text {
  color: white; 
}

.scanSystemSection {
  align-self: stretch;
  height: min-content;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
}

.systemTitle {
    display: flex;
    flex-direction: row;
    font-size: 24px;
    font-weight: 600;
}

.systemHeading {
    color: #3987E3;
}

.systemText {
    color: white;
}

.scanSystemContent,
.orderSystemContent {
    align-self: stretch;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.emptyDivider {
    width: 1px;
    height: 680px;
    position: relative;
}

.phoneBlock,
.scannerBlock {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.phoneBlock div,
.scannerBlock div {
    font-size: 24px;
    font-weight: 500;
    line-height: 29.74px;
    color: #3A3D43;
}

.phoneImage,
.scannerImage {
    width: 296px;
    height: 640px;
    position: relative;
    border: 2.5px solid #3A3D43;
    border-radius: 24px;
}

.scanText,
.orderText {
    text-align: center;
    width: 100%;
    color: white;
    font-size: 20px;
    font-weight: 400;
    line-height: 24.78px;
}

.scanText {
    width: 390px;
}

.orderSystemSection {
    align-self: stretch;
    height: min-content;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
}

.dashboardBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.dashboardBlock div {
    font-size: 24px;
    font-weight: 500;
    line-height: 29.74px;
    color: #3A3D43;
}

.dashboardImage {
    width: 1023px;
    height: 650px;
    position: relative;
    border: 2.5px solid #3A3D43;
    border-radius: 24px;
}

.productFooterSection {
    align-self: stretch;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 24px;
    width: 100%;
}

.productFooterBlock {
    padding: 32px;
    border-radius: 24px;
    border: 2px solid #3987E3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 24px;
    flex: 1;
}

.productFooterSide {
  border-radius: 24px;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 24px;
  height: 100%;
}


.productFooterHeading {
    font-size: 20px;
    font-weight: 400;
    line-height: 24.78px;
    color: #3987E3;
    font-weight: 600;
}

.productFooterText {
  font-size: 16px;
  font-weight: 400; /* Font weight for the main text */
  line-height: 20.05px;
  color: white;
}

.productFooterText ul {
  list-style-type: none; /* Remove default bullet points */
  padding-left: 0; /* Remove default padding */
  margin-top: 10px; /* Add some margin above the list */
}

.productFooterText ul li {
  position: relative;
  padding-left: 20px; /* Space to make room for custom bullet */
  font-weight: 500; /* Font weight for the list items */
  color: white; /* List text color */
  line-height: 1.5; /* Line height for better readability */
}

.productFooterText ul li::before {
  content: '●'; /* Add blue bullet */
  color: var(--accent-color); /* Blue color for the bullet */
  position: absolute;
  left: 0; /* Align bullet to the left of the text */
  font-size: 14px; /* Adjust size of the bullet */
  line-height: 20px;
}


.finalSection {
  width: 100%;
  padding-bottom: 160px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 60px;
}

.finalHeading {
  align-self: stretch;
  text-align: center;
  color: #FAFAFA;
  font-size: 36px;
  font-weight: 500;
  line-height: 44.46px;
  word-wrap: break-word;
  margin-top: 160px; /* Added space for top spacing */
  margin-bottom: 120px; /* Added space for bottom spacing */
}

.valueContent {
  align-self: stretch;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 60px;
}

.valueBlock {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
}

.valueHeading {
  align-self: stretch;
  text-align: center;
  color: #3987E3;
  font-size: 24px;
  font-weight: 600;
  line-height: 29.74px;
  word-wrap: break-word;
}

.valueText {
  color: #FAFAFA;
  font-size: 20px;
  font-weight: 400;
  line-height: 24.78px;
  word-wrap: break-word;
}

.valueText {
  list-style-type: none; /* Remove default bullets */
  padding-left: 0; /* Remove left padding */
  margin-top: 10px; /* Add space between heading and list */
}

.valueText li {
  position: relative;
  padding-left: 20px; /* Add space for custom bullet */
  font-weight: 500; /* Customize font weight if needed */
  color: white; /* Text color */
  line-height: 1.5; /* Improve readability */
}

.valueText li::before {
  content: '●'; /* Custom bullet */
  color: var(--accent-color); /* Accent color for bullet */
  position: absolute;
  left: 0;
  font-size: 14px;
  line-height: 20px;
}

@media screen and (max-width: 960px) {
  .mainSection {
    flex-direction: column;
    align-items: center;
  }

  .leftColumn {
    padding: 40px 20px;
  }

  .largeText {
    font-size: 32px;
  }

  .businessDescription {
    font-size: 18px;
  }

  .mainImage {
    width: 80%;
  }

  .contentSections {
    gap: 40px;
  }

  .customerFlow
  {
    padding: 0 40px;
    font-size: 18px;
  }

  .optionsSection {
    flex-direction: column;
    gap: 32px;
  }

  .optionBlock {
    gap: 4px;
  }

  .optionTitle {
    font-size: 18px;
    flex-direction: column;
  }

  .optionDescription {
    font-size: 16px;
  }

  .scanSystemSection,
  .orderSystemSection {
    gap: 20px;
  }

  .scanSystemContent,
  .orderSystemContent {
    flex-direction: column;
    gap: 40px;
    align-self: center;
    justify-content: start;
  }

  .emptyDivider {
    display: none;
  }

 

  .dashboardBlock {
    gap: 20px;
    display: flex;
  }

  .dashboardImage {
    width: 100%;
    height: auto;
    object-fit: contain;
  }

  .orderText{
    font-size: 18px;
  }

  .productFooterSection {
    flex-direction: column;
    gap: 40px;
    padding: 0;
    align-items: center;
  }

  .productFooterBlock {
    margin: 0px 20px;
    padding: 24px;
    gap: 20px;
  }

  .productFooterSide {
    gap: 20px;
  }

  .productFooterHeading {
    font-size: 18px;
  }
  

  .valueContent {
    flex-direction: column;
    align-items: center;
    gap: 40px;
}
}
