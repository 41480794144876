
.navbar {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: start;
    vertical-align: center;
    position: sticky;
    top: 0;
    z-index: 999;
}

.navbar-container {
    display: flex;
    width: 100%;
    height: min-content;
    align-items: center;
    padding: 16px 32px;
    vertical-align: bottom;
}

.nav-menu {
    display: flex;
    align-items: center;
    margin: 0;
}

.navbar-logo {
    display: flex;
    width: 128;
    color: white;
    cursor: pointer;
}

.menu-icon {
    color: white;
}

.spacer
{
    flex: 1 0 0;
    align-self: stretch;
}

.nav-links {
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    padding: 16px 20px;
    font-size: 20px;
    font-weight: 400;
}

.nav-links:hover {
    border-bottom: 2px solid white;
    color: var(--accent-color);
    transition: all 0.5s ease;
    text-decoration: none;
}

.nav-links-big {
    display: none;
}

.nav-menu.active {
    display: none;
    left: 0;
}

.menu-icon {
    display: none;
}

.nav-item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.button-icon {
    color: var(--accent-color);
    width: 20px;
    height: 20px;
    stroke-width: 2px;
}

@media screen and (min-width: 961) {
    .nav-menu {
        left: 0;
        top: 0;
        opacity: 1;
        transition: all 0.5s ease;
    }
}

@media screen and (max-width: 960px) {  
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100vw;
      position: absolute;
      top: 80px;
      left: -120vw;
      right: 0;
      opacity: 1;
      transition: all 0.5s ease;
      margin: 0 24px;
      padding: 0;
      grid-gap: 24px;
    }

    .navbar-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
  
    .nav-menu.active {
        display: flex;
        width: auto;
        flex-direction: column;
        align-items: center;
        left: 0;
        right: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
        background-color: var(--dark-color);
        border-radius: 12px;
        padding: 32px 32px; 
    }

    .nav-item {
        display: flex;
        width: 100%;
        justify-content: center;
        height: 100%;
    }
  
    .nav-links {
      text-align: center;
      width: 100%;
      display: table;
      padding: 0;
    }
  
    .navbar-logo {
        display: flex;
        align-items: center;
        position: relative;
        margin: 0; /* Remove any default margin */
    }
  
    .menu-icon {
      display: flex;
      position: relative;
      font-size: 1.8rem;
      cursor: pointer;
      margin: 0;
    }
  
    .nav-links-big {
        display: block;
        text-align: center;
        border-radius: 8px;
        width: 100%;
        text-decoration: none;
        font-size: 1.5rem;
        background-color: var(--accent-color);
        color: white;
        padding: 8px 0;
        transition: all 0.3s ease-out;
    }
}