:root {
  --dark-color: #090d14;
  --accent-color: #3987E3;
  --readable-white: #fafafa;
  --steel-color: #3A3D43;
  --grey-color: #959BA5;
  --navy-color: #0E1729;
}

body {
  background-color: var(--dark-color);
  font-family: 'Outfit', 'Poppins', sans-serif;
  height: 100%; /* Ensure body takes full height */
  margin: 0;
  display: flex;
  flex-direction: column;
}

html {
  height: 100%; /* Ensure html takes full height */
}

#root {
  flex: 1;
  display: flex;
  flex-direction: column; /* Ensure #root acts as a flex container */
}

.container {
  flex: 1;
  display: flex;
  flex-direction: column; /* Ensure container fills available space */
  overflow: auto; /* Handle content overflow if necessary */
}

.content {
  flex: 1; /* Ensure content fills the remaining space */
  display: flex;
  flex-direction: column;
  z-index: 0; /* Ensure content is above the video */
}

@font-face {
  font-family: 'Outfit';
  src: url('./assets/fonts/Outfit.ttf') format('truetype');
  font-weight: 100 900; /* Define the range of weights */
  font-style: normal; /* Define the style (can include italic if needed) */
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

/* Italic */

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

/*@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}


@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy-Heavy.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}*/